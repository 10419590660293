import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HqNoticeSearchPageRoutingModule } from './notice-search-routing.module';

import { HqNoticeSearchPage } from './notice-search.page';
import { ComponentModule } from 'src/app/component/component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentModule,
    HqNoticeSearchPageRoutingModule
  ],
  declarations: [HqNoticeSearchPage]
})
export class HqNoticeSearchPageModule {}
